<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="dynamicRules"
          @finish="handleSubmit"
        >
          <a-form-item name="nama" label="Nama">
            <a-input v-model:value="formState.nama" placeholder="Masukkan Nama" />
          </a-form-item>
          <a-form-item name="nik" label="NIK">
            <a-input
              v-model:value="formState.nik"
              placeholder="Masukkan NIK"
              type="tel"
              v-mask="'## ## ## ###### ####'"
            />
          </a-form-item>
          <a-form-item name="perusahaan" label="Perusahaan">
            <a-input v-model:value="formState.perusahaan" placeholder="Masukkan Perusahaan" />
          </a-form-item>

          <a-form-item name="npwp" label="NPWP">
            <template v-if="!isNpwpSameAsNik">
              <a-input
                v-model:value="formState.npwp"
                placeholder="Masukkan NPWP"
                type="tel"
                :disabled="isNpwpSameAsNik"
                v-mask="'##.###.###.#-###.###'"
              />
            </template>
            <template v-else>
              <a-input
                v-model:value="formState.npwp"
                placeholder="Masukkan NPWP"
                type="tel"
                :disabled="isNpwpSameAsNik"
              />
            </template>
          </a-form-item>
          <a-form-item :labelCol="{ span: 0 }" :wrapperCol="{ offset: 6 }">
            <a-checkbox v-model:checked="isNpwpSameAsNik" @change="handleNpwpCheckboxChange">
              Format baru (sama dengan NIK)
            </a-checkbox>
          </a-form-item>

          <a-form-item name="jabatan" label="Jabatan">
            <a-input v-model:value="formState.jabatan" placeholder="Masukkan Jabatan" />
          </a-form-item>
          <a-form-item name="is_active" label="Status">
            <div style="display: flex; align-items: center; gap: 8px;">
              <a-switch v-model:checked="formState.is_active" />
              <span>{{ formState.is_active ? 'Aktif' : 'Non Aktif' }}</span>
            </div>
          </a-form-item>
          <a-form-item name="no_hp" label="No. HP / WA">
            <a-input v-model:value="formState.no_hp" placeholder="Masukkan No. HP / WA" type="number" />
          </a-form-item>
          <a-form-item name="no_telp" label="No. Telp Kantor">
            <a-input v-model:value="formState.no_telp" placeholder="Masukkan No. Telp Kantor" type="number" />
          </a-form-item>
          <a-form-item name="email" label="Email">
            <a-input type="email" v-model:value="formState.email" placeholder="Masukkan Email" />
          </a-form-item>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>
                <router-link to="/master/vendor">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { mask } from 'vue-the-mask';

const VendorForm = {
  name: 'VendorForm',
  components: { HorizontalFormStyleWrap, Main },
  directives: {
    mask,
  },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  computed: {
    dynamicRules() {
      const rules = { ...this.rules };
      if (this.mode === 'Ubah') {
        delete rules.nik;
        delete rules.npwp;
      }
      return rules;
    },
  },
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);

    const formState = reactive({
      nama: '',
      nik: '',
      perusahaan: '',
      npwp: '',
      jabatan: '',
      is_active: true,
      no_hp: '',
      no_telp: '',
      email: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleNpwpCheckboxChange = checked => {
      if (checked.target.checked) {
        formState.npwp = formState.nik; // Set NPWP sama dengan NIK
      } else {
        formState.npwp = '';
      }
    };

    const handleSubmit = values => {
      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'vendor',
          data: values,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.id,
          url: 'vendor',
          data: values,
        });
      }
    };

    const rules = {
      nama: [{ required: true, message: 'Harap Diisi', trigger: 'blur' }],
      nik: [
        { required: true, message: 'Harap Diisi', trigger: 'blur' },
        { min: 20, max: 20, message: 'NIK harus 16 digit', trigger: 'blur' },
      ],
      perusahaan: [{ required: true, message: 'Harap Diisi', trigger: 'blur' }],
      email: [{ type: 'email', message: 'Format Email Tidak Valid', trigger: 'blur' }],
      npwp: [
        { required: true, message: 'Harap Diisi', trigger: 'blur' },
        { min: 20, max: 20, message: 'NPWP harus 15 digit', trigger: 'blur' },
      ],
    };

    onMounted(() => {
      if (props.mode == 'Ubah') {
        dispatch('axiosSingleDataGet', {
          url: 'vendor',
          id: params.id,
        }).then(() => {
          Object.keys(crud.value).forEach(key => {
            if (key === 'nik' || key === 'npwp') {
              return;
            }
            formState[key] = crud.value[key];
          });
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleNpwpCheckboxChange,
      handleSubmit,
      isLoading,
      rules,
    };
  },
};

export default VendorForm;
</script>
